<template>
  <div>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <div v-if="loaded">
      <v-row v-if="customFieldsForEntity.length>0" class="pb-6 pt-3">
        <v-col >
          <KeyValueView
              v-for="customField of customFieldsForEntity"
              :key="customField.id"
              :customFieldValueId="customField.valueId"
              :title="customField.name"
              :edit-btn="{
                icon:'mdi-check-circle',
                entity: 'customFieldValue',
                id: customField.id,
                row: customField.value,
                type: customField.type
              }"
              :value="customField.value"
              @editEntry="editEntry"
          >
          </KeyValueView>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");
const KeyValueView = () => import("@/components/detailView/KeyValueView");

export default {
  name: "CustomFieldBlock",
  data() {
    return {
      loaded: false,
      message: msgObj(),
    }
  },
  computed: {
    ...mapGetters('settings', {
      customFieldsForEntity: 'customFieldsForEntity',
    }),
  },
  components: {
    KeyValueView,
    //Spinner,
    Message
  },
  props: {
    entity: {
      type: String,
      default() {
        return ''
      }
    },
    user: {
      type: Object,
      default() {
        return {}
      }
    },
    entryId: {
      type: String,
      default() {
        return ''
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.$store.dispatch('settings/setCustomFieldsForEntityEmpty', {}).then(() => {
        this.loaded = true
        this.$store.dispatch('settings/getCustomFieldsForEntity', {
          entity: this.entity,
          entryId: this.entryId
        }).catch((err) => {
          this.message = error(err)
        })
      }).catch((err) => {
        this.message = error(err)
      })
    }, 800)
  },
  methods: {
    editEntry(payload) {
      if (payload.customFieldValueId === null) {
        this.$store.dispatch('settings/createCustomFieldValue', {
          entryId: this.entryId,
          customFieldId: payload.id,
          value: payload.newValue,
          entity: this.entity
        }).then(() => {
          this.message = success("Erfolgreich erstellt")
        }).catch((err) => {
          this.message = error(err)
        })
      } else {
        this.$store.dispatch('customer/editField', {
          entity: "customFieldValue",
          uid: this.user.id,
          id: payload.customFieldValueId,
          newValue: payload.newValue,
          row: 'value'
        }).then(() => {
          this.message = success("Erfolgreich bearbeitet")
          this.$store.dispatch('settings/setCustomFieldsForEntityEmpty', {}).then(() => {
            this.loaded = true
            this.$store.dispatch('settings/getCustomFieldsForEntity', {
              entity: this.entity,
              entryId: this.entryId
            }).catch((err) => {
              this.message = error(err)
            })
          }).catch((err) => {
            this.message = error(err)
          })
        }).catch((err) => {
          this.message = error(err)
        })
      }
    }
  }
}
</script>
